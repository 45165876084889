<template>
  <page-component
    :page-title="pageTitle"
    :headers="headers"
    :items="items"
    :loading-data="!loaded"
    :no-data-text="noDataText"
    hide-filter-toggle
  >
    <template v-for="filter in filters" v-slot:[filter]>
      <slot :name="filter" />
    </template>
    <template v-slot:[`item.caseSize`]="props">
      <span v-if="props.item.caseSize">
        {{ props.item.caseSize }} pkg{{ props.item.caseSize != 1 ? "s" : "" }}
        <span v-if="props.item.packageMeasurement" class="text--lighttext">
          <br />
          {{ getCaseMeasurementWithUnit(props.item) }}
        </span>
      </span>
    </template>
    <template v-slot:[`item.packageSize`]="props">
      <span v-if="props.item.packageMeasurement">
        {{ getPackageMeasurementWithUnit(props.item) }}
      </span>
    </template>
    <template v-slot:[`item.quantity`]="props">
      <package-or-case-field
        v-model="props.item"
        @input="handleQuantityChange(props.item)"
      />
    </template>
    <template v-slot:[`item.totalSize`]="props">
      <span v-if="props.item.packageMeasurement && props.item.quantity">
        &asymp; {{ getTotalQuantityWithUnit(props.item) }}
      </span>
    </template>
    <template v-slot:[`item.cost`]="props">
      <v-edit-dialog
        v-if="allowCostEdit"
        persistent
        large
        @save="updatePackageCost(props.item)"
      >
        <span v-if="props.item.packageMeasurement">
          ${{ getCostPerPackageOrCase(props.item).toFixed(2) }}
        </span>
        {{ props.item.costMoney.currency }} per&nbsp;{{
          (props.item.quantityUnit === "cases" && "case") || "pkg"
        }}
        <v-icon small class="ml-2">mdi-pencil</v-icon>
        <template v-slot:input>
          <div v-if="props.item.packageMeasurement" class="mt-4">
            Enter New Package Cost (per
            {{ getPackageCostPerUnitName(props.item) }})
          </div>
          <v-text-field
            v-if="props.item.packageMeasurement"
            v-model="localAmount"
            class="mt-2 shrink"
            label="Edit"
            single-line
            type="number"
            :messages="`Current cost: $${
              props.item.costMoney.amount * props.item.packageMeasurement.quantity
            }`"
            outlined
            dense
          />
        </template>
      </v-edit-dialog>
      <span v-else>
        <span v-if="props.item.packageMeasurement">
          ${{ getCostPerPackageOrCase(props.item).toFixed(2) }}
        </span>
        <span>
          {{ props.item.costMoney.currency }} per&nbsp;{{
            (props.item.quantityUnit === "cases" && "case") || "pkg"
          }}
        </span>
      </span>
      <br />
      <span v-if="props.item.packageMeasurement" class="text--lighttext">
        ${{ getCostPerUnit(props.item).toFixed(2) }}
        {{ props.item.costMoney.currency }} per
        {{ getUnitName(props.item.packageMeasurement.preferredDisplayUnitId) }}
      </span>
    </template>
    <template v-slot:[`item.subtotal`]="props">
      <span v-if="props.item.packageMeasurement">
        ${{ parseFloat(getItemSubtotal(props.item)).toFixed(2) }}
      </span>
    </template>
  </page-component>
</template>

<script>
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"
import PackageOrCaseField from "@/AuthenticatedContent/shared/forms/PackageOrCaseField"

export default {
  name: "items-form",
  components: {
    PageComponent,
    PackageOrCaseField,
  },
  mixins: [topLevelMixins],
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    allowCostEdit: {
      type: Boolean,
      default: false,
    },
    quantityLabel: {
      type: String,
      default: "Quantity",
    },
    additionalHeaders: {
      type: Array,
      default: () => [],
    },
    noDataText: {
      type: String,
      default: "No items found",
    },
  },
  data() {
    return {
      items: this.value,
    }
  },
  computed: {
    headers() {
      const headers = [
        { text: "Supplier SKU", value: "supplierSKU" },
        { text: "Name", value: "name" },
        { text: "Case Size", value: "caseSize" },
        { text: "Package Size", value: "packageSize" },
      ]
      if (this.additionalHeaders.length) {
        headers.push(...this.additionalHeaders)
      }
      headers.push(
        ...[
          { text: this.quantityLabel, value: "quantity", width: 240 },
          { text: " ", value: "totalSize", width: 120 },
          { text: "Cost", value: "cost", width: 200 },
          { text: "Subtotal", value: "subtotal" },
        ]
      )
      return headers
    },
    filters() {
      return Object.keys(this.$scopedSlots).filter(name => name.startsWith("filter"))
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.items = newValue
      },
      immediate: true,
    },
  },
  methods: {
    handleQuantityChange(item) {
      this.$set(item, "subtotal", this.getItemSubtotal(item))
      this.$emit("change", item)
      this.$emit("input", this.items)
    },
    updatePackageCost(item) {
      item.costMoney.prevAmount = item.costMoney.amount
      item.costMoney.amount = this.localAmount / item.packageMeasurement.quantity
      this.$emit("change", item)
      this.$emit("input", this.items)
    },
    getItemSubtotal(item) {
      if (!item?.quantity) return 0
      const caseOrPackageCost = this.getCostPerPackageOrCase(item)
      return (parseFloat(caseOrPackageCost) * parseFloat(item.quantity)).toFixed(2)
    },
  },
}
</script>
