<template>
  <component
    :is="autocomplete ? 'v-autocomplete' : 'v-select'"
    v-model="selection"
    :label="selection.length || value !== null ? label : `${label} (None Selected)`"
    :items="items"
    :item-value="itemValue"
    :item-text="itemText"
    :menu-props="{ bottom: true, offsetY: true }"
    class="nxg-custom-select"
    outlined
    dense
    hide-details
    persistent-placeholder
    :multiple="multiple"
    :error-messages="errorMessages"
    :disabled="disabled"
    @change="handleChange"
  >
    <template v-if="multiple" v-slot:prepend-item>
      <v-list-item @click="selectAll">
        <v-list-item-action>
          <v-icon :color="allSelected ? 'success' : ''">mdi-check-all</v-icon>
        </v-list-item-action>
        <v-list-item-title>Select All</v-list-item-title>
      </v-list-item>
    </template>
    <template v-if="multiple" v-slot:selection="{ index }">
      <span v-if="index === 0" :class="{ 'with-autocomplete': autocomplete }">
        {{ smartLabel }}
      </span>
    </template>
  </component>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemValue: {
      type: String,
      required: false,
      default: "value",
    },
    itemText: {
      type: String,
      required: false,
      default: "text",
    },
    label: {
      type: String,
      required: false,
      default: "Items",
    },
    value: {
      type: [String, Array],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selection: this.value !== null ? this.value : this.multiple ? [] : "",
    }
  },
  computed: {
    allSelected() {
      return this.selection.length === this.items.length
    },
    smartLabel() {
      if (!this.multiple) return this.selection
      else if (this.allSelected)
        return (
          "All" +
          (this.selection.length > 10 ? " (" + this.selection.length + ")" : "")
        )
      else if (this.selection.length === 0) return "None"
      else if (this.selection.length === 1) return this.getSelectedItemName(0)
      else if (this.selection.length === 2) {
        const label =
          this.getSelectedItemName(0) + " / " + this.getSelectedItemName(1)
        if (label.length < 18) return label
      }
      if (this.selection.length === this.items?.length - 1)
        return "Not " + this.onlyItemNotSelectedName
      else return this.selection.length + " Selected"
    },
    onlyItemNotSelectedName() {
      const unselectedItem = this.items.find(
        item => !this.selection.includes(item[this.itemValue])
      )
      return unselectedItem ? unselectedItem[this.itemText] : ""
    },
  },
  watch: {
    value(newValue) {
      this.selection = newValue !== null ? newValue : this.multiple ? [] : ""
    },
  },
  methods: {
    selectAll() {
      if (this.allSelected) {
        this.selection = []
      } else {
        this.selection = this.items.map(item =>
          typeof item === "string" ? item : item[this.itemValue]
        )
      }
      this.$emit("input", this.selection)
    },
    handleChange() {
      this.$emit("input", this.selection)
    },
    getSelectedItemName(i) {
      const val = this.selection[i]
      const selectedItem = this.items.find(item => item[this.itemValue] === val)
      return selectedItem ? selectedItem[this.itemText] : ""
    },
  },
}
</script>

<style lang="scss">
.nxg-custom-select {
  z-index: 2;
  .v-select__selections {
    > span {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &.with-autocomplete {
        max-width: 50%;
      }
    }
    > input:not([readonly]) {
      box-shadow: 0 0 2px #ddd inset;
      background: #dddddd50;
      margin: 0 0 0 10px;
      border-radius: 6px;
      border: solid 1px #ddd;
      padding: 1px 3px;
    }
  }
}
</style>
