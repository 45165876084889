<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'Review'"
      :title="historyView ? 'Receiving Details' : 'Review and Submit Receiving'"
      :showFormTitle="false"
      :closeDisabled="awaitingResponse"
      :awaitingResponse="awaitingResponse"
      :submitDisabled="awaitingResponse || historyView"
      :showSubmit="!historyView"
      @close="$emit('close')"
      @submit="submitHandle"
    >
      <v-row>
        <v-col cols="3">
          <v-list flat dense color="transparent" class="pa-0">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>Date:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ formattedDate }}
                  @
                  {{ formattedTime }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>Submitted by:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ userDisplayName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="3">
          <v-list flat dense color="transparent" class="pa-0">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>Supplier:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ getSupplierName(orderGuide.supplierId) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>Order guide:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ orderGuide.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-textarea
            v-if="!historyView"
            v-model="comment"
            label="Comments"
            outlined
            rows="3"
          />
          <v-list v-else flat dense color="transparent" class="pa-0">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>Comments:</v-list-item-title>
                <v-list-item-subtitle style="text-wrap: wrap">
                  {{ comments }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="itemsReceived"
        color="secondary"
        class="my-4"
        disable-pagination
        hide-default-footer
      >
        <template v-if="!historyView" v-slot:[`item.prevMeasurement`]="{ item }">
          {{ getPreviousQuantity(item) }}
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <!-- if quantityUnit is cases, multiply by item.caseSize -->
          <span
            :class="{
              'green--text': item.quantity > 0,
              'red--text': item.quantity < 0,
            }"
          >
            {{
              (item.quantity > 0 ? "+" : "") +
              parseFloat(item.quantity) *
                getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) *
                (item.quantityUnit === "cases" ? item.caseSize : 1) +
              " " +
              getUnitName(
                item.packageMeasurement.preferredDisplayUnitId,
                item.quantity
              )
            }}
          </span>
        </template>
        <template v-if="!historyView" v-slot:[`item.newMeasurement`]="{ item }">
          {{ getNewQuantity(item) }}
        </template>
        <template v-slot:[`item.unitCost`]="{ item }">
          <span v-if="item.packageMeasurement">
            ${{ getCostPerUnit(item).toFixed(2) }} {{ item.costMoney.currency }} per
            {{ getUnitName(item.packageMeasurement.preferredDisplayUnitId) }}
          </span>
        </template>
        <template v-slot:[`item.totalCost`]="{ item }">
          <span v-if="item.packageMeasurement">
            ${{ getReceivingQuantityCost(item).toFixed(2) }}
          </span>
          {{ item.costMoney.currency }}
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td v-if="!historyView" colspan="6">
              Total ({{ itemsReceived.length }} items)
            </td>
            <td v-else colspan="4">Total ({{ itemsReceived.length }} items)</td>
            <td>
              ${{ getTotalCost().toFixed(2) }}
              {{
                itemsReceived && itemsReceived.length
                  ? itemsReceived[0].costMoney.currency
                  : ""
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </form-dialog>
  </div>
</template>

<script>
import db from "@/firebaseConfig"
import { doc, writeBatch, runTransaction, Timestamp } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import moment from "moment"
import FormDialog from "@/AuthenticatedContent/shared/dialogs/FormDialog.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"
import { mapState, mapGetters, mapActions } from "vuex"

export default {
  name: "review-form",
  components: { FormDialog },
  mixins: [topLevelMixins],
  props: {
    date: {
      type: String,
      required: true,
    },
    orderGuide: {
      type: Object,
      required: true,
    },
    itemsReceived: {
      type: Array,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    comments: {
      type: String,
      required: false,
      default: "",
    },
    historyView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comment: null,
      awaitingResponse: false,
    }
  },
  computed: {
    ...mapGetters(["getSupplierName", "getUnitName"]),
    ...mapState([
      "firebaseRefs",
      "currentUserAccess",
      "supplierItems",
      "users",
      "units",
      "items",
    ]),
    headers() {
      return [
        { text: "Supplier SKU", value: "supplierSKU" },
        { text: "Name", value: "name" },
        { text: "Prev Qty", value: "prevMeasurement", condition: !this.historyView },
        { text: "Received Stock", value: "quantity" },
        { text: "New Qty", value: "newMeasurement", condition: !this.historyView },
        { text: "Unit Cost", value: "unitCost" },
        { text: "Total Cost", value: "totalCost" },
      ].filter(header => header.condition === undefined || header.condition)
    },
    formattedDate() {
      return this.date
        ? moment(this.date, "YYYY-MM-DD HH:mm:ss").format("MMM DD, YYYY")
        : ""
    },
    formattedTime() {
      return this.date
        ? moment(this.date, "YYYY-MM-DD HH:mm:ss").format("h:mm a")
        : ""
    },
    orderGuideId() {
      return this.orderGuide.id
    },
    userDisplayName() {
      const user = getAuth().currentUser
      if (!user) return "Anonymous"
      if (user?.displayName) return user.displayName
      else if (this.users) {
        const userDoc = Object.values(this.users)?.find(u => u.userId === user.uid)
        if (userDoc?.name) return userDoc.name
      }
      return user.email
    },
    dateToSave() {
      return this.date
        ? Timestamp.fromDate(moment(this.date, "YYYY-MM-DD HH:mm:ss").toDate())
        : null
    },
  },
  methods: {
    ...mapActions(["updatePurchaseOrders"]),
    async submitHandle() {
      if (this.awaitingResponse || this.historyView) return

      this.awaitingResponse = true
      try {
        // Start a Firestore transaction
        await runTransaction(db, async transaction => {
          const batch = writeBatch(db)

          // Create receiving entry
          const receivingId = doc(this.firebaseRefs.receivingsRef).id
          const receiving = {
            id: receivingId,
            date: this.dateToSave,
            orderGuideId: this.orderGuideId,
            totalCost: this.getTotalCost(),
            userId: this.currentUserAccess?.userId,
            comment: this.comment,
            itemsReceived: this.itemsReceived,
            ...(Object.keys(this.purchaseOrder).length > 0
              ? { purchaseOrderId: this.purchaseOrder.id }
              : {}),
          }

          batch.set(doc(this.firebaseRefs.receivingsRef, receivingId), receiving)

          const currentItemQuantities = []
          for (let i = 0; i < this.itemsReceived.length; i++) {
            if (this.itemsReceived[i].linkedItemID) {
              const itemDocRef = doc(
                this.firebaseRefs.itemsRef,
                this.itemsReceived[i].linkedItemID
              )
              const itemDoc = await transaction.get(itemDocRef)
              currentItemQuantities[i] =
                itemDoc.data().measurement || this.createEmptyMeasurementObject()
            }
          }

          // Handle changes to inventory items, supplier items, and inventory item logs
          for (let i = 0; i < this.itemsReceived.length; i++) {
            if (this.itemsReceived[i].linkedItemID) {
              const itemDocRef = doc(
                this.firebaseRefs.itemsRef,
                this.itemsReceived[i].linkedItemID
              )
              const prevQuantity = currentItemQuantities[i].quantity || 0
              const quantity =
                parseFloat(this.itemsReceived[i].quantity) *
                  parseFloat(this.itemsReceived[i].packageMeasurement.quantity) *
                  (this.itemsReceived[i].quantityUnit === "cases"
                    ? this.itemsReceived[i].caseSize
                    : 1) +
                parseFloat(prevQuantity)

              // Update quantity of inventory item
              transaction.update(itemDocRef, {
                measurement: {
                  quantity: quantity,
                  unit: this.itemsReceived[i].packageMeasurement.unit,
                  preferredDisplayUnitId:
                    this.itemsReceived[i].packageMeasurement.preferredDisplayUnitId,
                },
              })

              // Add the inventory item log entry
              const inventoryItemLogId = doc(
                this.firebaseRefs.inventoryitemslogRef
              ).id
              batch.set(
                doc(this.firebaseRefs.inventoryitemslogRef, inventoryItemLogId),
                {
                  id: inventoryItemLogId,
                  date: this.dateToSave,
                  triggerType: "Receiving",
                  receivingID: receivingId,
                  itemID: this.itemsReceived[i].linkedItemID,
                  prevMeasurement: {
                    quantity: prevQuantity,
                    unit: this.itemsReceived[i].packageMeasurement.unit,
                    preferredDisplayUnitId:
                      this.itemsReceived[i].packageMeasurement
                        .preferredDisplayUnitId,
                  },
                  delta: {
                    quantity: quantity - prevQuantity,
                    unit: this.itemsReceived[i].packageMeasurement.unit,
                    preferredDisplayUnitId:
                      this.itemsReceived[i].packageMeasurement
                        .preferredDisplayUnitId,
                  },
                  measurement: {
                    quantity: quantity,
                    unit: this.itemsReceived[i].packageMeasurement.unit,
                    preferredDisplayUnitId:
                      this.itemsReceived[i].packageMeasurement
                        .preferredDisplayUnitId,
                  },
                  costMoney: this.itemsReceived[i].costMoney,
                }
              )

              // Record any cost changes into the supplier item cost and cost history
              let supplierItemDeltaChanges = {
                costMoney: this.itemsReceived[i].costMoney,
              }
              if (
                this.itemsReceived[i].costMoney.prevAmount &&
                this.itemsReceived[i].costMoney.prevAmount !==
                  this.itemsReceived[i].costMoney.amount
              ) {
                const supplierItemCostHistory =
                  this.itemsReceived[i].costHistory || []
                // Add it to the cost history (for supplier items)
                supplierItemCostHistory.push({
                  ...this.itemsReceived[i].costMoney,
                  date: this.dateToSave,
                  receivingID: receivingId,
                })
                // Must update cost history
                supplierItemDeltaChanges.costHistory = supplierItemCostHistory
              }

              // Update the supplier item with the new cost and the updated cost history (if any)
              const supplierItemDocRef = doc(
                this.firebaseRefs.supplieritemsRef,
                this.itemsReceived[i].id
              )
              transaction.update(supplierItemDocRef, supplierItemDeltaChanges)
            }
          }

          // Commit the batch
          await batch
            .commit()
            .then(() => {
              this.awaitingResponse = false
              this.$emit("success")

              if (Object.keys(this.purchaseOrder).length > 0) {
                this.updatePurchaseOrderStatus(this.purchaseOrder, receivingId)
              }
            })
            .catch(e => {
              this.awaitingResponse = false
              console.error("Error: ", e)
            })
        })
      } catch (e) {
        this.awaitingResponse = false
        console.error("Error: ", e)
      }
    },
    getAssociatedSupplierItem(itemId) {
      if (!itemId || !this.supplierItems.length) return
      return this.supplierItems.find(
        supplierItem => supplierItem.linkedItemID === itemId
      )
    },
    getTotalCost() {
      return this.itemsReceived.reduce((sum, sItem) => {
        return sum + Math.round(this.getReceivingQuantityCost(sItem) * 100) / 100
      }, 0)
    },
    getCurrentMeasurementQuantity(supplierItem) {
      if (this.items && this.items.length > 0)
        return (
          this.items.find(i => i.itemID === supplierItem.linkedItemID).measurement
            ?.quantity || 0
        )
    },
    getNewQuantity(item) {
      const currentItem = this.items.find(
        inventoryItem => inventoryItem.itemID === item.linkedItemID
      )
      if (!currentItem) return ""
      const quantityUnitSize = item.quantityUnit === "cases" ? item.caseSize : 1

      return (
        parseFloat(
          this.getQuantityInBaseUnits(
            this.getCurrentMeasurementQuantity(item),
            item.id
          ) +
            parseFloat(item.quantity) *
              parseFloat(
                this.getQuantityInBaseUnits(
                  item.packageMeasurement.quantity,
                  item.id
                )
              )
        ) *
          quantityUnitSize +
        " " +
        this.getUnitName(item.packageMeasurement.preferredDisplayUnitId) +
        "s"
      )
    },
    async updatePurchaseOrderStatus(purchaseOrder, receivingId) {
      const updatedPurchaseOrder = {
        ...purchaseOrder,
        status: "Received",
        receivingId: receivingId,
      }
      await this.updatePurchaseOrders(updatedPurchaseOrder)
    },
    getBaseUnit(itemId) {
      return this.units.find(
        u => u.id === this.getAssociatedSupplierItem(itemId).supplierUnit
      ).base
    },
    getReceivingQuantity(item) {
      return (
        (item.quantity > 0 ? "+" : "") +
        parseFloat(item.quantity) *
          parseFloat(
            this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id)
          ) +
        " " +
        this.getUnitName(item.packageMeasurement.preferredDisplayUnitId)
      )
    },
    getReceivingQuantityCost(item) {
      const cost = item.quantityUnit === "cases" ? item.caseSize : 1
      return (
        Math.round(
          this.getCostPerUnit(item) *
            item.quantity *
            cost *
            this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) *
            100
        ) / 100
      )
    },
    getPreviousQuantity(item) {
      const currentItem = this.items.find(
        inventoryItem => inventoryItem.itemID === item.id
      )
      if (!currentItem) return ""
      return (
        this.getQuantityInBaseUnits(
          this.getCurrentMeasurementQuantity(item),
          item.id
        ) +
        " " +
        this.getUnitName(
          item.packageMeasurement.preferredDisplayUnitId,
          item.quantity
        )
      )
    },
  },
}
</script>

<style scoped lang="scss">
.v-data-table tbody tr:last-child {
  background-color: var(--v-primary-base) !important;
  color: var(--v-text-on-primary-bg-base) !important;
  font-weight: bold;
}
</style>
